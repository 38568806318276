"use strict";

var _interopRequireDefault = require("E:/project/xuexiao/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("E:/project/xuexiao/back-end/app/firm/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("E:\\project\\xuexiao\\back-end\\app\\firm\\node_modules\\core-js\\modules\\es6.array.iterator.js");
require("E:\\project\\xuexiao\\back-end\\app\\firm\\node_modules\\core-js\\modules\\es6.promise.js");
require("E:\\project\\xuexiao\\back-end\\app\\firm\\node_modules\\core-js\\modules\\es6.object.assign.js");
require("E:\\project\\xuexiao\\back-end\\app\\firm\\node_modules\\core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
var _microApp = _interopRequireDefault(require("@micro-zoe/micro-app"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _waves = _interopRequireDefault(require("@/directive/waves"));
var _auth = _interopRequireDefault(require("@/directive/auth"));
require("./icons");
require("./auth");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
require("element-ui/lib/theme-chalk/index.css");
var _elementUi2 = _interopRequireDefault(require("@form-create/element-ui"));
var _File = _interopRequireDefault(require("@/components/FormCreate/File"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
// a modern alternative to CSS resets

// global css

// 水波纹指令

// 权限指令

// icon
// auth control
// error log

// global filters
// ElementUI

// 表单插件

_elementUi2.default.component('file-library', _File.default);
_vue.default.use(_auth.default);
_vue.default.use(_waves.default);
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small' // set element-ui default size
});

_vue.default.use(_elementUi2.default);
// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
_microApp.default.start();
new _vue.default({
  el: '#start',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});