"use strict";

var _interopRequireDefault = require("E:/project/xuexiao/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _config = require("@/api/config");
var _userForm = _interopRequireDefault(require("./components/userForm"));
var _loginImage = _interopRequireDefault(require("./components/loginImage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  components: {
    userForm: _userForm.default,
    loginImage: _loginImage.default
  },
  data: function data() {
    return {
      config: {
        title: '',
        background_image: ''
      }
    };
  },
  created: function created() {
    var _this = this;
    (0, _config.getInfo)({
      app: 'core'
    }).then(function (res) {
      if (!res.code) {
        _this.config = res.data;
      }
      _this.config = res.data;
    });
  }
};
exports.default = _default;