"use strict";

var _interopRequireDefault = require("E:/project/xuexiao/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("E:/project/xuexiao/back-end/app/firm/node_modules/@babel/runtime/helpers/typeof.js"));
require("core-js/modules/es6.number.constructor");
var _openWindow = _interopRequireDefault(require("@/utils/open-window"));
var _library = _interopRequireDefault(require("./library"));
var _utils = require("@/utils");
var _file = require("@/api/file");
var _mixins = _interopRequireDefault(require("./mixins"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'FileLibrary',
  components: {
    library: _library.default
  },
  mixins: [_mixins.default],
  props: {
    /**
      * value: 数组或对象形式。因为双向绑定，所以可能会出现字符串形式。
      * returnIds：控制返回值是否为id拼凑的字符串，默认返回路径地址数组
      * disabled: 禁用选择，默认 false
      * limit: 限制上传数，默认 5
      * multiple: library组件中使用，是否可以多选上传
      * uploadObj: library组件中使用，上传是携带的参数，默认{ folder_id: '' }
      * accept: 配置上传所选的类型，默认'image/*'
      * size: 配置上传所选的大小，默认5MB
      * library: 是否使用图片库
    * */
    value: [Object, Array, String],
    // 外部 Object, Array 传入的value值，用于图片操作。 String 为内部调整
    returnIds: {
      // 控制返回值是否为id拼凑的字符串
      type: [Boolean, Number],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limit: {
      type: [Number, String],
      default: 5
    },
    accept: {
      type: String,
      default: 'image/*,video/*,.dwg,.dxf,.psd,.ai,.pdf,.txt,.md,.doc,.docx,.xls,.xlsx,.pptx,.mp4'
    },
    size: {
      type: Number,
      default: 5
    },
    width: {
      type: Number,
      default: 60
    },
    height: {
      type: Number,
      default: 60
    },
    library: {
      type: Boolean,
      default: true
    },
    center: {
      Boolean: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      srcList: [],
      dialogVisible: false,
      dialogImage: {},
      imgLoading: false
    };
  },
  watch: {
    value: {
      handler: function handler(val) {
        if (val == '') this.srcList = [];
        // 转化字符串后，会再次进入，需要将字符串判定退出
        if (typeof val === 'string') {
          if (val.indexOf('http') > -1) {
            this.srcList = [val];
          }
          return;
        }
        if (val && (0, _typeof2.default)(val) === 'object') {
          if (!Array.isArray(val)) {
            val = [val];
          }
          this.srcList = val;
        } else {
          this.srcList = [];
        }
        // console.log(this.srcList)
      },

      immediate: true,
      deep: true
    },
    srcList: {
      handler: function handler(val) {
        this.handleEmit(val);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 查看大图
    handleShow: function handleShow(image) {
      // this.dialogImage = image
      // this.dialogVisible = true
      console.log(image);
      (0, _openWindow.default)(image.url || image, '预览', '500', '400');
    },
    // 删除图片某个图片列表
    handleDelete: function handleDelete(index) {
      var _this = this;
      if (!this.disabled) {
        this.$confirm('是否要删除所选图片', '提示', {
          center: true
        }).then(function () {
          _this.srcList.splice(index, 1);
          _this.$message.success('删除成功');
        }).catch(function () {
          _this.$message.warning('您取消了删除');
        });
      }
    },
    handleDialog: function handleDialog() {
      if (!this.disabled) {
        this.$refs.fileDialog.handleShow();
      }
    },
    // 将数组转为字符串
    handleEmit: function handleEmit(list) {
      if (Array.isArray(list)) {
        list.map(function (item) {
          if (item.id) {
            item.file_id = item.id;
          }
        });
      }
      if (this.returnIds) {
        var ids = (0, _utils.getArrByKey)(list, 'file_id').join(',');
        this.$emit('input', ids);
      } else {
        if (this.limit === 1) {
          this.$emit('input', list.map(function (item) {
            return item.url || item;
          }).join(','));
        } else {
          this.$emit('input', list.map(function (item) {
            return item.url || item;
          }));
        }
      }
    },
    // 手动上传
    handleUpload: function handleUpload(data) {
      var _this2 = this;
      var formData = this.createFormData(data, this.upLoadData, this.accept, this.size);
      if (!formData) {
        return false;
      }
      this.imgLoading = true;
      (0, _file.upload)(formData).then(function (response) {
        _this2.updateRow([response.data]);
        _this2.imgLoading = false;
      }).catch(function (error) {
        console.log(error);
        _this2.imgLoading = false;
      });
    },
    updateRow: function updateRow(list) {
      var srcList = this.srcList.concat(list);
      if (srcList.length > this.limit) {
        srcList.splice(this.limit);
      }
      this.srcList = [].concat(srcList);
    }
  }
};
exports.default = _default;