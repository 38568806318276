"use strict";

var _interopRequireDefault = require("E:/project/xuexiao/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInfo = getInfo;
exports.getPage = getPage;
exports.remove = remove;
exports.transfer = transfer;
exports.update = update;
exports.upload = upload;
var _request = _interopRequireDefault(require("@/utils/request"));
function getPage() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/storage/file/page',
    method: 'get',
    params: params
  });
}
function getInfo(id) {
  return (0, _request.default)({
    url: '/storage/file/info',
    method: 'get',
    params: {
      id: id
    }
  });
}
function transfer(data) {
  return (0, _request.default)({
    url: '/storage/file/transfer',
    method: 'post',
    data: data
  });
}
function update(data) {
  return (0, _request.default)({
    url: '/storage/file/update',
    method: 'post',
    data: data
  });
}
function remove(data) {
  return (0, _request.default)({
    url: '/storage/file/remove',
    method: 'post',
    data: data
  });
}
function upload(data) {
  return (0, _request.default)({
    url: '/storage/upload/file',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    type: 'form-data',
    data: data
  });
}