"use strict";

var _interopRequireDefault = require("E:/project/xuexiao/back-end/app/firm/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("E:/project/xuexiao/back-end/app/firm/node_modules/@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
var _file = require("@/api/file");
var _folder = require("@/api/folder");
var _utils = require("@/utils");
var _group = _interopRequireDefault(require("./group"));
var _vuex = require("vuex");
var _mixins = _interopRequireDefault(require("./mixins"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default2 = {
  name: 'Library',
  components: {
    groupDialog: _group.default
  },
  mixins: [_mixins.default],
  props: {
    limit: {
      type: [Number, String],
      default: Infinity
    },
    multiple: {
      type: Boolean,
      default: true
    },
    uploadObj: {
      type: Object,
      default: function _default() {
        return {
          folder_id: 0
        };
      }
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    size: {
      type: Number,
      default: 5
    }
  },
  data: function data() {
    return {
      visible: false,
      tabsValue: '-1',
      imgLoading: false,
      editableTabs: [{
        id: -1,
        title: '全部',
        name: '-1'
      }, {
        id: 0,
        title: '未分组',
        name: '0'
      }],
      imageData: {
        data: [],
        total: 0
      },
      imageCheck: [],
      imageFilter: {
        page: 1,
        per_page: 30
      }
    };
  },
  computed: {
    upLoadData: function upLoadData() {
      return JSON.parse(JSON.stringify(this.uploadObj));
    }
  },
  mounted: function mounted() {
    this.tabsMounted();
  },
  methods: _objectSpread(_objectSpread({
    initData: function initData() {
      this.imageFilter.folder_id = this.tabsValue;
      if (this.tabsValue == -1) {
        delete this.imageFilter.folder_id;
      }
      this.imageFilter.page = 1;
      this.fetchList();
      this.imageFilter = {
        page: 1,
        per_page: 30
      };
      this.imageCheck = [];
    },
    fetchList: function fetchList() {
      var _this = this;
      this.imgLoading = true;
      (0, _file.getPage)(this.imageFilter).then(function (response) {
        var imageData = response.data;
        imageData.data.forEach(function (item) {
          item.status = false;
        });
        _this.imageData = imageData;
        _this.imgLoading = false;
      }).catch(function () {
        _this.imgLoading = true;
      });
    },
    // 跳转页
    handleCurrentChange: function handleCurrentChange(val) {
      this.imageFilter.page = val;
      this.fetchList();
    },
    // 展示图片库
    handleShow: function handleShow() {
      this.visible = true;
      this.initData();
    },
    // 关闭图片库
    handleClose: function handleClose() {
      this.visible = false;
    },
    // 选择图片
    handleCheck: function handleCheck(index, img) {
      var status = this.imageData.data[index].status;
      if (!status) {
        if (this.imageCheck.length >= this.limit) {
          this.$message.warning('该内容只能插入' + this.limit + '张图片');
          return false;
        }
        if (!this.imageCheck.some(function (item) {
          return img.id === item.id;
        })) {
          this.imageCheck.push(img);
        }
      } else {
        this.imageCheck = this.imageCheck.filter(function (item) {
          return item.id !== img.id;
        });
      }
      this.imageData.data[index].status = !status;
    },
    // 添加分组
    handleAdd: function handleAdd() {
      this.$refs.groupDialog.handleCreate();
    },
    // 删除图片
    handleCancel: function handleCancel() {
      var _this2 = this;
      var ids = (0, _utils.getArrByKey)(this.imageCheck, 'id');
      if (ids.length < 2) {
        ids = ids.toString();
      } else {
        ids = ids.join(',');
      }
      this.$confirm('是否删除所选图片', '提示', {}).then(function () {
        (0, _file.remove)({
          id: ids
        }).then(function (response) {
          if (response.code == 1) {
            _this2.initData();
            _this2.$message.success(response.msg);
          } else {
            _this2.$message.error(response.msg);
          }
        }).catch(function (error) {
          _this2.$message.error(error.message);
        });
      }).catch(function () {
        _this2.$message.error('您取消了删除');
      });
    },
    // 删除分组
    handleCancelGroup: function handleCancelGroup(data) {
      var _this3 = this;
      if (data.id > 0) {
        this.$confirm('是否删除分组', '提示', {}).then(function () {
          (0, _folder.remove)({
            id: data.id
          }).then(function (response) {
            if (response.code == 1) {
              var tabs = _this3.editableTabs;
              var activeName = _this3.tabsValue;
              if (activeName == data.name) {
                tabs.forEach(function (tab, index) {
                  if (tab.name == data.name) {
                    var nextTab = tabs[index + 1] || tabs[index - 1];
                    if (nextTab) {
                      activeName = nextTab.name;
                    }
                  }
                });
              }
              _this3.tabsValue = activeName;
              _this3.editableTabs = tabs.filter(function (tab) {
                return tab.name != data.name;
              });
              _this3.$message.success(response.msg);
              _this3.initData();
            } else {
              _this3.$message.error(response.msg);
            }
          }).catch(function (error) {
            _this3.$message.error(error.message);
          });
        }).catch(function () {
          _this3.$message.warning('您取消了删除');
        });
      }
    },
    // 移动分组
    handleTransfer: function handleTransfer(groupId) {
      var _this4 = this;
      var ids = (0, _utils.getArrByKey)(this.imageCheck, 'id');
      if (ids.length > 0) {
        this.imgLoading = true;
        (0, _file.transfer)({
          id: ids,
          folder_id: groupId
        }).then(function (response) {
          if (response.code == 1) {
            _this4.initData();
            _this4.$message.success(response.msg);
          } else {
            _this4.$message.error(response.msg);
          }
          _this4.imgLoading = false;
        }).catch(function (error) {
          _this4.$message.error(error.message);
          _this4.imgLoading = false;
        });
      } else {
        this.$message.error('请选择所需要移动的图片');
      }
    },
    // 编辑分组
    handleEdit: function handleEdit(row) {
      this.$refs.groupDialog.handleEdit(row);
    },
    // 手动上传
    handleUpload: function handleUpload(data) {
      var _this5 = this;
      var formData = this.createFormData(data, this.upLoadData, this.accept, this.size);
      if (!formData) {
        return false;
      }
      this.imgLoading = true;
      (0, _file.upload)(formData).then(function (response) {
        _this5.initData();
        _this5.imgLoading = false;
      }).catch(function (error) {
        _this5.initData();
        console.log(error);
        _this5.imgLoading = false;
      });
    },
    // 切换分组
    handleChange: function handleChange(id) {
      // 获取数据
      this.tabsValue = id;
      this.upLoadData.folder_id = id;
      this.initData();
      return true;
    },
    // 添加/编辑分组
    updateTabs: function updateTabs(form) {
      var index = this.editableTabs.some(function (item) {
        if (item.id == form.id) {
          item.title = form.title;
          return true;
        } else {
          return false;
        }
      });
      if (!index) {
        this.editableTabs.push(form);
      }
      this.tabsValue = form.name;
    },
    // 确认选择的图片
    updateRow: function updateRow() {
      this.imageCheck.sort(function (a, b) {
        return a.id - b.id;
      });
      this.$emit('updateRow', this.imageCheck);
      this.handleClose();
    }
  }, (0, _vuex.mapActions)({
    getFolder: 'storage/getFolder'
  })), {}, {
    tabsMounted: function tabsMounted() {
      var _this6 = this;
      this.getFolder().then(function (response) {
        _this6.editableTabs = _this6.editableTabs.concat(response);
      });
    }
  })
};
exports.default = _default2;